
  import ProductCardInfo from '../productCard/ProductCardInfo.vue';
  import checkProductsPrices from '@/plugins/check.products.prices';
  const Cookie = require('js-cookie');

  export default {
    mixins: [checkProductsPrices],
    components: {
      ProductCardInfo,
    },
    data() {
      return {
        show: 6,
        loading: false,
        products: [],
        recentlyViewedIds: [],
      };
    },
    async mounted() {
      const ids = Cookie.get('recently_viewed');
      this.recentlyViewedIds = ids ? JSON.parse(ids) : [];

      if (this.recentlyViewedIds.length > 0 && !this.$store.getters.IS_BOT_SERVER) {
        this.products = (await this.$api.products.getProducts(this.recentlyViewedIds)).data;
      }
    },
    computed: {
      availableList() {
        const index = (product) => this.recentlyViewedIds.indexOf(product._id);
        return this.products.sort((a, b) => (index(a) > index(b) ? 1 : -1)).filter((el, index) => index < this.show);
      },
    },
    methods: {
      loadMore() {
        this.show += 6;
      },
    },
  };
